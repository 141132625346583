export default {
    day: '2024-09-29',
    content: {
        gallery: [
            {
                unlock: 'galleryConversion',
                type: 'balance',
                text: '395',
                balance: 'buff',
                before: '1.5x',
                after: '1.9x'
            },
        ],
    }
}
