export default {
    day: '2024-09-29',
    content: {
        village: [
            {
                unlock: 'villageOffering1',
                type: 'bugfix',
                text: '396'
            }
        ],
        event: [
            {
                type: 'bugfix',
                text: '397'
            }
        ],
        village_1: [
            {
                type: 'bugfix',
                text: '398'
            }
        ]
    }
}
