export default {
    gardenGnome: {
        icon: 'mdi-human-child'
    },
    sprinkler: {
        icon: 'mdi-sprinkler-variant'
    },
    lectern: {
        icon: 'mdi-book-open-page-variant'
    },
    pinwheel: {
        icon: 'mdi-pinwheel'
    },
    flag: {
        icon: 'mdi-flag'
    }
}
