export default {
  miningPickaxeCrafting: 'Pickaxe crafting',
  miningDepthDweller: 'Depth dweller',
  miningSmeltery: 'Smeltery',
  miningEnhancement: 'Enhancements',
  miningResin: 'Resin',
  miningSmoke: 'Smoke',
  miningCompressAluminium: 'Aluminium compression',
  miningCompressCopper: 'Copper compression',
  miningCompressTin: 'Tin compression',
  miningCompressIron: 'Iron compression',
  miningCompressTitanium: 'Titanium compression',
  miningCompressPlatinum: 'Platinum compression',
  miningCompressIridium: 'Iridium compression',
  miningCompressOsmium: 'Osmium compression',
  miningCompressLead: 'Lead compression',
  villageBuildings1: 'Tier 1 buildings',
  villageBuildings2: 'Tier 2 buildings',
  villageBuildings3: 'Tier 3 buildings',
  villageBuildings4: 'Tier 4 buildings',
  villageBuildings5: 'Tier 5 buildings',
  villageBuildings6: 'Tier 6 buildings',
  villageBuildings7: 'Tier 7 buildings',
  villageCoinUpgrades: 'Gold coin upgrades',
  villageUpgradeScythe: 'Scythe upgrade',
  villageUpgradeHatchet: 'Hatchet upgrade',
  villageUpgradePickaxe: 'Pickaxe upgrade',
  villageUpgradeWateringCan: 'Watering can upgrade',
  villageUpgradeInvestment: 'Investment upgrade',
  villageUpgradeBasics: 'Basics upgrade',
  villageUpgradeProcessing: 'Processing upgrade',
  villageUpgradePump: 'Pump upgrade',
  villageUpgradeSand: 'Sand upgrade',
  villageUpgradeBook: 'Book upgrade',
  villageUpgradeAxe: 'Axe upgrade',
  villageUpgradeBomb: 'Bomb upgrade',
  villageUpgradeToll: 'Toll upgrade',
  villageUpgradeFishingRod: 'Fishing rod upgrade',
  villageUpgradeHolyBook: 'Holy book upgrade',
  villageUpgradeBreakthrough: 'Breakthrough upgrade',
  villageUpgradeModifiedPlants: 'Modified plants upgrade',
  villageUpgradeDopamine: 'Dopamine upgrade',
  villageUpgradeAdrenaline: 'Adrenaline upgrade',
  villageUpgradeSprinkler: 'Sprinkler upgrade',
  villageUpgradeGreed: 'Greed upgrade',
  villageUpgradeAmbition: 'Ambition upgrade',
  villageUpgradeUnderstanding: 'Understanding upgrade',
  villageUpgradeCuriosity: 'Curiosity upgrade',
  villageUpgradeWorship: 'Worship upgrade',
  villageUpgradeBartering: 'Bartering upgrade',
  villageUpgradeSparks: 'Sparks upgrade',
  villageOffering1: 'Offerings',
  villageOffering2: 'Tier 2 offerings',
  villageOffering3: 'Tier 3 offerings',
  villageOffering4: 'Tier 4 offerings',
  villageSpecialIngredient: 'Special ingredients',
  hordeCorruptedFlesh: 'Loot corrupted flesh',
  hordeSacrifice: 'Sacrifice',
  hordeChessItems: 'Chess equipment',
  hordeUpgradeRoyalArmor: 'Royal armor upgrade',
  hordeUpgradeRoyalStorage: 'Royal storage upgrade',
  hordeUpgradeRoyalButcher: 'Royal butcher upgrade',
  hordeUpgradeRoyalCrypt: 'Royal crypt upgrade',
  hordeUpgradeRoyalSecret: 'Royal secret upgrade',
  hordeClassArcher: 'Archer class',
  hordeClassMage: 'Mage class',
  hordeClassKnight: 'Knight class',
  hordeClassAssassin: 'Assassin class',
  hordeClassShaman: 'Shaman class',
  hordeClassPirate: 'Pirate class',
  hordeClassUndead: 'Undead class',
  hordeClassCultist: 'Cultist class',
  hordeClassScholar: 'Scholar class',
  farmDisableEarlyGame: 'Disable "First crops"',
  farmCropExp: 'Crop experience',
  farmFertilizer: 'Fertilizer',
  galleryConversion: 'Conversion',
  galleryInspiration: 'Inspiration',
  galleryAuction: 'Auctions',
  galleryDrums: 'Drums',
  galleryShape: 'Shapes',
  galleryCanvas: 'Canvas',
  treasureDual: 'Dual treasure',
  bloomPoppyFlower: 'Poppy',
  bloomIrisFlower: 'Iris',
  bloomLilyFlower: 'Lily',
  bloomOrchidFlower: 'Orchid',
  bloomCornflowerFlower: 'Cornflower',
  summerFestivalTerraform: 'Terraforming'
};
