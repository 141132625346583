export default {
    price: 1000,
    light: {
        primary: '#D219D2',
        secondary: '#424242',
        accent: '#FF82FF',
    },
    dark: {
        primary: '#D219D2',
        secondary: '#424242',
        accent: '#FF82FF',
    }
}
