export default {
    price: 1000,
    light: {
        primary: '#D21919',
        secondary: '#424242',
        accent: '#FF8282',
    },
    dark: {
        primary: '#D21919',
        secondary: '#424242',
        accent: '#FF8282',
    }
}
