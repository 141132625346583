export default {
    day: '2023-10-31',
    content: {
        meta: [
            {
                type: 'bugfix',
                text: '228'
            }
        ],
        event: [
            {
                type: 'bugfix',
                text: '229'
            }
        ]
    }
}
