import { render, staticRenderFns } from "./ShapeMinigame.vue?vue&type=template&id=320a161e&scoped=true"
import script from "./ShapeMinigame.vue?vue&type=script&lang=js"
export * from "./ShapeMinigame.vue?vue&type=script&lang=js"
import style0 from "./ShapeMinigame.vue?vue&type=style&index=0&id=320a161e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "320a161e",
  null
  
)

export default component.exports